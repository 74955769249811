import { Injectable } from '@angular/core';
import { getFirebaseBackend } from '../../firebaseBackend/firestoreUtils';
import { AuthorityService } from './authority.service';
import { arrayRemove } from "firebase/firestore";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private AuthorityService: AuthorityService) { }

  createCompany(company: any) {
    const collectionPathCompany = `companies`;
    return getFirebaseBackend().createNewDocumentByCollectionPath(collectionPathCompany, company).then((Company: any) => {
      this.addCompanyToUserRecord(Company.id, company.companyName, "owner");
      return Company.id;
    })
  }
  addCompanyToUserRecord(companyID: string, companyName: string, userRole: string) {
    var AuthUser = getFirebaseBackend().getAuthenticatedUser();
    const docPathUser = `users/${AuthUser.uid}`;
    getFirebaseBackend().getDocumentByDocPath(docPathUser).then((user: any) => {
      const userCompanyRole = {
        companies: {
          [companyID]: {
            name: companyName,
            role: userRole
          },
          ...user.companies
        }
      }
      getFirebaseBackend().editDocumentByDocPath(docPathUser, userCompanyRole);
    });
  }
  addProjecToComapanyRecord(pid: string, cid: string) {
    const docPthCompany = `companies/${cid}`;
    return getFirebaseBackend().getDocumentByDocPath(docPthCompany).then((company: any) => {
      var projects = company.projects ? company.projects : [];
      projects.push(pid);
      company.projects = projects
      getFirebaseBackend().editDocumentByDocPath(docPthCompany, company).then(() => {
        return company
      })
    })
  }
  getUserCompanies(cb) {
    const AuthUser = getFirebaseBackend().getAuthenticatedUser();
    const docPathUser = `users/${AuthUser.uid}`;
    return getFirebaseBackend().getDocumentWithListener(docPathUser, async(user, err) => {
      if (!err) {
        const companies:any = [];
        for (const cid in user.companies) {
          const companyData = await this.getCompanyData(cid);
          if(companyData.users.includes(AuthUser.uid)){
            companies.push(companyData);
          }
        }
        cb(companies, false);
      }
      else {
        cb(null, true);
      }
    })
  }

  getUserByEmail(email:string){
    return getFirebaseBackend().getDocByUniqueProperty("users","email",email).then((res)=>{
      return res
    })
  }


  getCompanyData(cid: string) {
    const docPath = `companies/${cid}`;
    return getFirebaseBackend().getDocumentByDocPath(docPath).then((company: any) => {
      return company;
    })
  }

  getCompanyDataWithUpdate(cid:string,cb){
    const docPath = `companies/${cid}`;
    getFirebaseBackend().getDocumentWithListener(docPath,(company: any,err) => {
      cb( company,err);
    })
  }


  getCompanyAdmins(cid: string) {
    const usersRecords:any = []
    return this.getCompanyData(cid).then((company: any) => {
      company.users.forEach(uid => {
        getFirebaseBackend().getDocumentByDocPath(`users/${uid}`).then((user: any) => {
          const userRecord = {
            uid: uid,
            name: user.firstName + " " + user.lastName,
            email: user.email,
          }
          usersRecords.push(userRecord);
        })
      });
      return usersRecords;
    })
  }

  addAdminToCompany(uid: string, cid: string,role:string) {
    return this.getCompanyData(cid).then(async(company: any) => {
      var users = company.users;
      users.push(uid);
      const docPathCompany = `companies/${cid}`;
      await getFirebaseBackend().editDocumentByDocPath(docPathCompany, { users: users }).then(async()=>{
        await this.addCompanyToAdmin(uid, cid, company.companyName,role);
        return true;
      })
    })
  }

  addCompanyToAdmin(uid: string, cid: string, Cname: string,role:string) {
    const docPathUser = `users/${uid}`;
    return getFirebaseBackend().getDocumentByDocPath(docPathUser).then((user: any) => {
      // console.log(user.companies)
      var userCompany;
      userCompany = {
        companies: {
          [cid]: {
            name: Cname,
            role: role
          },
          ...user.companies
        }
      }
      getFirebaseBackend().editDocumentByDocPath(docPathUser, userCompany).then(()=>{
        return true;
      })
    })
  }

  removeComapnyFromUser = async (uid: string, cid: string) => {
    const AuthUser = await getFirebaseBackend().getAuthenticatedUser();
    if (uid !== AuthUser.uid) {
      const docPathUser = `users/${uid}`;
      getFirebaseBackend().getDocumentByDocPath(docPathUser).then(async(user: any) => {
        if (user.companies[cid].role !== 'owner') {
          delete user.companies[cid];
          await getFirebaseBackend().editDocumentByDocPath(docPathUser, { 'companies': user.companies }).then(() => {
            this.removeUserFromComapny(uid, cid);
            return true
          })
        }
      })
    }
    else {
      alert("you cannot delete your self")
    }
  }

  removeUserFromComapny(uid: string, cid: string) {
    const docPathCompany = `companies/${cid}`;
    return getFirebaseBackend().editDocumentByDocPath(docPathCompany, { 'users': arrayRemove(uid)}).then(()=>{
      return true;
    })
  }

  updateOwnerToAdmin(newOwnerId: string, cid: string) {
    const AuthUserID = getFirebaseBackend().getAuthenticatedUser().uid;
    const docPathUser = `users/${AuthUserID}`;
    return getFirebaseBackend().getDocumentByDocPath(docPathUser).then(async (user: any) => {
      if (user.companies[cid].role === 'owner') {
        user.companies[cid].role = 'admin';
        await getFirebaseBackend().editDocumentByDocPath(docPathUser, { "companies": user.companies }).then(async () => {
          await this.updateAdminToOwner(newOwnerId, cid).then(() => {
            return true;
          })
        })
      }
    })
  }
  updateAdminToOwner(newOwnerId: string, cid: string) {
    const docPathUser = `users/${newOwnerId}`;
    return getFirebaseBackend().getDocumentByDocPath(docPathUser).then(async (user: any) => {
      if (user.companies[cid].role === 'admin') {
        user.companies[cid].role = 'owner';
        await getFirebaseBackend().editDocumentByDocPath(docPathUser, { "companies": user.companies }).then(() => {
          return true
        })
      }
    })
  }

  updateCompany(cid:string, company:any){
    const docPathCompany = `companies/${cid}`;
    return getFirebaseBackend().editDocumentByDocPath(docPathCompany, company).then(()=>{
      return true;
    })
  }



}
